@import "../../abstracts/variables"
@import "../../abstracts/mixins"

.familyPlans
	.react-tabs__tab-panel--selected
		animation: fade-in 0.5s ease
		-webkit-animation: fade-in 0.5s ease
		-moz-animation: fade-in 0.5s ease
	.familyPlansIntro
		text-align: center
		margin-bottom: $spacer2
		h1, h2, h3
			+ p
				margin-top: $spacer2
	.famPlansMenu
		+bx
		+borderRadius(16px)
		+shadow
		+justifyCont(space-between)
		+alignItem
		position: relative
		background-color: #fff
		padding: $spacer3 / 2
		.famPlansMenuWrap
			+bx
			overflow: auto
			padding: 6px 0
			margin: -6px 0
			position: relative
			&::-webkit-scrollbar
				-webkit-appearance: none
				height: 6px
			&::-webkit-scrollbar-thumb
				+borderRadius(4px)
				background-color: rgba(0, 0, 0, 0.5)
			.react-tabs__tab-list
				+flex
				border-bottom: none
				margin-bottom: 0
			.react-tabs__tab
				+bx
				+borderRadius(8px)
				+font-size(14px)
				font-weight: 700
				display: block
				color: $gris5
				padding: $spacer0 $spacer2
				white-space: nowrap
				+ .react-tabs__tab
					margin-left: $spacer - 2px
			.react-tabs__tab--selected
				color: $gris7
				border: none
				background-color: $gris3
		.textLink
			display: none
	.famPlansCont
		width: calc(100% + 40px)
		margin-left: -$spacer2
		margin-right: -$spacer2
		margin-top: $spacer3
		.famPlansContCard
			padding: $spacer 0 $spacer $spacer2
		.slick-list
			padding: 0 15% 0 0 !important
	.familyPlansBtn
		margin-top: $spacer3
		[class*="btn"]
			width: 100%
			max-width: 450px
			margin: 0 auto
			display: table

+mediaMin760
	.familyPlans
		.famPlansMenu
			padding: $spacer2
			.famPlansMenuWrap
				.react-tabs__tab
					+font-size(18px)
					padding: $spacer $spacer3
		
+mediaMin960
	.familyPlans
		.familyPlansIntro
			margin-bottom: $spacer3
			h1, h2, h3
				+ p
					margin-top: $spacer3 / 2
		.famPlansCont
			.slider
				+flex
				+flexWrap
				width: 100%
			.famPlansContCard
				+bx
				width: 33.33%
				padding: 0 #{$spacer2}
				.cPlanV2Wrap
					display: table
					margin-left: auto
					margin-right: auto
		.familyPlansBtn
			[class*="btn"]
				max-width: 320px
			

+mediaMin1280
	.familyPlans
		.famPlansMenu
			+flex
			.famPlansMenuWrap
				overflow: visible
				padding-right: $spacer2
			.textLink
				display: block
		.famPlansCont
			width: calc(100% + 20px)
			margin-left: -#{$spacer - 2px}
			margin-right: -#{$spacer - 2px}
			.famPlansContCard
				width: 25%
				padding: 0 #{$spacer - 2px}

@keyframes fade-in
	0%
		opacity: 0
	100%
		opacity: 1

@-webkit-keyframes fade-in
	0%
		opacity: 0
	100%
		opacity: 1

@-moz-keyframes fade-in
	0%
		opacity: 0
	100%
		opacity: 1